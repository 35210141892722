html,
body {
  background-color: #171319;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-x: hidden;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 16px;
  left: 50%;
  translate: -50% 0%;
}

.hero {
  mask: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  min-height: 50vh;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -37% -50%;
  width: 90vw;
}

.captions {
  position: absolute;
  top: 60%;
  width: 100vw;
}

.android {
  padding-bottom: 20px;
  color: rgb(11, 170, 99);
}

#emailBoxWrapper {
  position: relative;
  margin-top: 4vh;
}

.hero img {
  border-radius: 18px;
}

.App {
  min-height: calc(100vh - 4rem);
}

@supports (height: 100svh) {
  .App {
    min-height: calc(100svh - 6rem);
  }
}

.cool {
  position: absolute;
  min-width: 100vw;
  min-height: calc(50vh - 4rem);
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(7, 5, 50));
}

.classname {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .hero {
    position: absolute;
    height: 100%;
    width: 60vw;
    left: 20%;
  }

  .title {
    position: absolute;
    width: 55vw;
    top: 30vh;
  }

  .captions {
    position: absolute;
    top: 40vh;
  }

  #emailBoxWrapper {
    position: absolute;
    top: 55vh;
    width: 100%;
  }
}